<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6">
                <form action="#">
                    <div class="input-group input-group-lg">
                        <input type="search" class="form-control form-control" placeholder="Cari Mata Pelajaran">
                        <div class="input-group-append">
                            <button type="submit" class="btn btn btn-default">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            <br>
            <div  class="row">
                <div v-for="row in optMapel" class="col-md-4 col-sm-8 col-12">
                <router-link :to="{
                    path: '/materi-ajar-content/'+row.id
                }">
                    <div class="info-box shadow-sm">
                    <span class="info-box-icon bg-info shadow"><img src="https://img.icons8.com/external-vitaliy-gorbachev-lineal-color-vitaly-gorbachev/50/000000/external-books-back-to-school-vitaliy-gorbachev-lineal-color-vitaly-gorbachev.png"/></span>
                    <div class="info-box-content">
                        <span class="info-box-text">{{ row.mapel }}</span>
                        <span class="info-box-number">{{ !row.qty_materi ? 0 : row.qty_materi}} Materi</span>
                    </div>
                    </div>
                </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
export default {
    name: 'Materi',
    data: () => ({
        optMapel: [],
    }),
    created: function () {
        // this.loadMateri();
    },
    methods: {
        loadMateri(){
            authFetch("/siswa/materi").then((res) => {
                res.json().then((json) => {
                if (json.success) {
                    this.optMapel = json.data;
                }
                });
            });
        }
    },
    mounted(){
        this.loadMateri();
    },
}
</script>
<style type="text/css" scoped>
</style>